import { Md5 } from 'ts-md5';
import { HttpService } from './../../services/http.service';
import { Router, ActivatedRoute, NavigationExtras, RoutesRecognized } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { StorageService } from './../../services/storage.service';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Title,Meta } from '@angular/platform-browser';
declare var editormd: any;

@Component({
  selector: 'app-b-article-detail',
  templateUrl: './b-article-detail.component.html',
  styleUrls: ['./b-article-detail.component.less']
})
export class BArticleDetailComponent implements OnInit {
  userinfo: any = {};         //本地保存的登录用户信息
  isLogin = false;     // 是否已经登录
  articleId = 0;              //文章id
  articleDetail: any = {};    //文章详情
  hotColumns: any = [];       //排行榜TOP5
  authorInfo: any = [];       //作者信息

  answer1List: any = [];      //一级评论列表
  answer2List: any = [];      //二级评论列表

  answerComment = '';             //双向绑定用于提交评论的输入框文本
  plackText = "评论ta";   //提交评论输入框的缺省文字
  replyBtnText = "提交评论";      //提交评论按钮的文字
  replyUserId = 0;
  answerId = 0;                   //被回复答案的id，当answertype为1回答问题的时候，此处送0

  advert = true;
  offsetTop = 0;

  value: any;   //临时，防止报错
  isShowComments = false;   //是否展开评论

  isreward = false; // 赞赏作者弹框
  thanks = false;  
  error = false;
  allPgdou = 0;  // 可用pg豆
  pgdouList = [2,5,10,20,66,88];
  pgdouChecked:number;   //选中的PG豆数量
  custom_pgdou:number;   //自定义的PG豆数量
  repgdou = 0; //剩余pgdou

  isMore = false;       //是否显示更多
  editor: any;
  quillContent: any;    //富文本数据
  quillConfig = {
    toolbar: [
      ['bold', 'italic', 'size'],
      ['link', 'blockquote', 'code-block', 'image'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, 'code-block', { 'header': 1 }, { 'header': 2 }],
      [{ 'indent': '-1' }, { 'indent': '+1' }], [{ 'color': [] }],
    ]
  };

  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    public http: HttpService,
    private route: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
  ) {
    window.scrollTo(0,0);
    // console.log("已经constructor!");
    route.params.subscribe((data) => {
      this.articleId = data.id;
    });
    this.userinfo = this.local.get('userinfo');
    if (this.local.get('userinfo') != null) {
      this.isLogin = true;
    }
    
    this.advert = true;
    // this.subscription = router.events.subscribe(event => {
    //   if (event instanceof RoutesRecognized) {
    //     // console.log("RoutesRecognized:", event.url);
    //     if (event.url.indexOf('a?id=') > -1) {
    //       console.log("已经跳到bolgs-detail!");
    //       this.articleId = Number(event.url.substr(event.url.indexOf('a?id=') + 5));
    //       this.getArticleDetail();
    //       this.getAnswerList();
    //     }
    //   }
    // });
  }

  ngOnInit() {
    // console.log("已经调到bolgs-detail!");
    //获取文章详情数据column/columns/getArticleDetail
    this.getArticleDetail();

    //获取该文章的一级评论列表
    this.getAnswerList();

    //获取热门专栏列表
    this.getHotColumns()
  }

  // subscription: Subscription;
  ngOnDestroy(): void {
    // this.subscription.unsubscribe();
    this.titleService.setTitle('PGFans问答社区:全球唯一的PostgreSQL中文技术交流社区');
    this.meta.updateTag({ name: 'description', content: 'PGFans问答社区由中国开源软件联盟PostgreSQL分会发起，是全球唯一的PostgreSQL中文技术交流社区，PGFans问答社区主要服务于与PostgreSQL相关的DBA、应用开发、内核开发人员，作为世界上最先进的开源数据库，PostgreSQL发展趋势不断向上，并荣获2017、2018、2020年年度数据库。'});
    this.meta.updateTag({ name: 'keywords', content: 'PG社区,问答社区,技术社区'});
  }


  /**
   * 通过用户id获取用户PG豆信息
   */
  getUserInfo() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getnewinfo');
    var api = 'user/user/getNewInfo';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userinfo.id,
      'my_user_id': 0,
      'sessionid': 0,
    }
    if (this.local.get('userinfo') == null) {
      body.my_user_id = 0;
    } else {
      body.my_user_id = this.local.get('userinfo').id;
      body.sessionid = this.local.get('userinfo').sessionid;
    }
    // console.log("body:", body);
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.allPgdou = result.data.pgdou;
      }
    })
  }

  close(){
    this.advert = false;
  }

  reward(){
    if (this.local.get('userinfo') == null || this.local.get('userinfo').id < 0) {
      this.router.navigate(['/login']);
    }else{
      this.isreward = true;
      this.getUserInfo();
    }
  }
  handleCancel(){
    this.custom_pgdou = undefined;
    this.isreward = false;
  }
  cancel(){
    this.thanks = false;
    this.error = false;
  }

  /**
   * 赞赏作者/user/pgdou/rewardAuthor
   */
  handleOk(){
    if(this.custom_pgdou===undefined&&this.pgdouChecked===undefined){
      this.modalService.warning({
        nzTitle: "系统提示",
        nzContent: "您尚未选中赞赏的PG豆数量！",
        nzOkText: "关闭",
        nzOnOk: () => {},
      })
    }else{
      this.isreward = false;
      
      var pgdou = 0;
      if(this.pgdouChecked!=undefined){
        pgdou = this.pgdouChecked;
      }else{
        pgdou = this.custom_pgdou;
      }

      let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|rewardauthor');
      var api = 'user/pgdou/rewardAuthor';
      var body = {
        'timestamp': new Date().getTime().toString().substr(0, 10),
        'signature': signature,
        'user_id': this.userinfo.id,
        'article_id': this.articleId,
        'pgdou': pgdou,
      }
      this.http.doPost(api, body, (result) => {
        // console.log(result);
        if (result.code == "200") {
          this.thanks = true;
          this.repgdou = result.data.pgdou;
        }else{
          this.error = true;
          // this.modalService.error({
          //   nzTitle: "系统提示",
          //   nzContent: result.message,
          //   nzOkText: "关闭",
          //   nzOnOk: () => {},
          // })
        }
      });
    }
    
  }

  checked(i){
    this.custom_pgdou = undefined;
    this.pgdouChecked = this.pgdouList[i];
    $('.pgdou-item').css('background','#FFFFFF');
    $('.pgdou-item').css('border','1px solid #BFBFBF');
    $('#item'+i).css('border','1px solid #00AED9');
    $('#item'+i).css('background','#d9f1f8');
  }

  changeNum(){
    this.pgdouChecked = undefined;
    $('.pgdou-item').css('border','1px solid #BFBFBF');
    $('.pgdou-item').css('background','#FFFFFF');
  }
  /**
   * 获取问答详情数据column/columns/getArticleDetail
   */
  getArticleDetail() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getarticledetail');
    var api = 'column/columns/getArticleDetail';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'article_id': this.articleId,
      'user_id': null,
    }
    if (this.userinfo) {
      body.user_id = this.userinfo.id;
    }
    this.http.doPost(api, body, (result) => {
      // console.log(result)
      if (result.code == "200") {
        this.titleService.setTitle(result.data.title+'-'+result.data.user_login+'-PGFans问答社区:全球唯一的PostgreSQL中文技术交流社区');
        result.data.update_time = result.data.update_time + '000';
        this.articleDetail = result.data;
        // console.log(this.articleDetail)
        //登录查看原文
        if(this.isLogin == false){
          var sub = Math.floor(this.articleDetail.content.length/2);
          this.articleDetail.content = this.articleDetail.content.substring(0,sub);
        }
        if (this.articleDetail.content_type == 2) {
          this.articleDetail.content = this.articleDetail.content.replace('<p>', '');
          this.articleDetail.content = this.articleDetail.content.replace('</p>', '');
          
          if ($('#q_html').html() == '') {
            $('#q_html').append("<textarea style='display:none;' placeholder='markdown语言'>" + this.articleDetail.content + "</textarea>");
          }
          editormd.markdownToHTML('q_html');
        } else {
          this.articleDetail.content = this.articleDetail.content.replace(/\<img/g, "<img class='c_img' ")
          this.articleDetail.content = this.articleDetail.content + "<style>.c_img{max-width:100%;}p{margin:0 auto;}.ql-syntax{word-wrap:break-word;}</style>"
          document.getElementById('q_html').innerHTML = this.articleDetail.content;
        }
        this.getAuthorInfo(result.data.user_id);
        this.setMeta();
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => {
            this.router.navigate(['/']);
          },
        })
      }
    })
  }

    //设置meta关键字
    setMeta(){
      var words = '';
      for(let i=0;i<this.articleDetail.labelList.length;i++){
        words = words+this.articleDetail.labelList[i].label+',';
      }
      // console.log(words)
      this.meta.updateTag({ name: 'description', content: this.articleDetail.introduce});
      this.meta.updateTag({ name: 'keywords', content: words })
    }

  goUrl(url) {
    if (url.indexOf('http') < 0) {
      url = 'http://' + url;
    }
    window.open(url, '_blank')
  }

  /**
   * 获取热门专栏column/columns/getHotColumns
   */
  getHotColumns() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|gethotcolumns');
    var api = 'column/columns/getHotColumns';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'count': 5,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.hotColumns = result.data;
        }
      } else {
        // this.modalService.error({
        //   nzTitle: "系统提示",
        //   nzContent: result.message,
        //   nzOkText: "关闭",
        //   nzOnOk: () => { },
        // })
      }
    })
  }
  /**
   * 获取所有该文章的一级评论
   */
  getAnswerList() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|answerlist');
    var api = 'column/article_answer/answerList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'answer_type': 1,
      'parent_answer_id': 0,
      'article_id': this.articleId,
      'user_id': null,
    }
    if (this.userinfo) {
      body.user_id = this.userinfo.id;
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          setTimeout(() => {
            for (let i = 0; i < result.data.length; i++) {
              // this.answer1List[i].create_time = this.answer1List[i].create_time + '000';
              result.data[i].content = result.data[i].content.replace(/\<img/g, "<img class='c_img' ")
              result.data[i].content = result.data[i].content + "<style>.c_img{max-width:100%;}p{margin:0 auto;}</style>"
              // document.getElementById('answer_html' + i).innerHTML = this.answer1List[i].content;
            }
            this.answer1List = result.data;
          }, 10);
        }
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }

    })
  }
  contentChanged(event) {
    this.quillContent = event.html;  // 内容编辑
  }
  goEdit(qd) {
    let navigationExtras: NavigationExtras = {
      queryParams: { id: JSON.stringify(qd.id), },
    }
    this.router.navigate(['/blogs-edit'], navigationExtras);
  }
  /**删除文章question/question/deleteQuestion */
  delArticle(article) {
    this.modalService.warning({
      nzTitle: '删除',
      nzContent: '<font>该操作不可逆，是否确认删除？</font>',
      nzOkText: '确认',
      nzOnOk: () => {
        let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|deletecolumnarticle');
        var api = 'column/columns/deleteColumnArticle';
        var body = {
          'timestamp': new Date().getTime().toString().substr(0, 10),
          'signature': signature,
          'article_id': article.id,
          'user_id': this.userinfo.id,
        }
        // console.log("body", body);
        this.http.doPost(api, body, (result) => {
          // console.log(result);
          if (result.code == "200") {
            this.modalService.success({
              nzTitle: "系统提示",
              nzContent: "删除文章成功",
              nzOkText: "关闭",
              nzOnOk: () => {
                window.history.back();
              },
            })
          }
        })
      },
      nzCancelText: '取消',
      nzOnCancel: () => { },
    });
  }

  doShowReAnswer(id, key) {
    this.answer2List = [];
    if (this.answer1List[key].checked) {
      this.answer1List[key].checked = false;
      return;
    }
    for (let i = 0; i < this.answer1List.length; i++) {
      if (this.answer1List[i].checked) {
        this.answer1List[i].checked = false;
      }
    }
    this.getReAnswer(id, key);
  }
  /**
   * 获取此一级评论的二级评论列表
   */
  getReAnswer(id, key) {
    this.plackText = "评论ta";
    this.replyBtnText = "提交评论";
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|answerlist');
    var api = 'column/article_answer/answerList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'answer_type': 2,
      'parent_answer_id': id,
      'article_id': this.articleId,
      'user_id': null,
    }
    if (this.userinfo) {
      body.user_id = this.userinfo.id;
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.answer2List = result.data;
        for (let i = 0; i < this.answer1List.length; i++) {
          if (key == i) {
            this.answer1List[key].checked = true;
            this.answer1List[key].answer_count = this.answer2List.length;
          }
        }
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }

  /**
   * 添加文章评论column/article_answer/addAnswer
   */
  doAddAnswer() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addanswer');
    var api = 'column/article_answer/addAnswer';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'article_id': this.articleId,
      'answer_type': 1,
      'answer_id': this.answerId,
      'user_id': this.userinfo.id,
      'parent_answer_id': 0,
      'reply_answer_type': 1,
      'content': this.quillContent,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.quillContent = '';
        this.getAnswerList();
        // this.modalService.success({
        //   nzTitle: "系统提示",
        //   nzContent: "回复成功!",
        //   nzOkText: "关闭",
        //   nzOnOk: () => {
        //     this.quillContent = '';
        //     this.getArticleDetail();
        //     this.getAnswerList();
        //   },
        // })
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }

  /**
   * 提交评论（二级回复）
   */
  addComment(answerId, key) {
    var replyAnswerType = 1;
    if (this.replyBtnText == "回复") {
      replyAnswerType = 2
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addanswer');
    var api = 'column/article_answer/addAnswer';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'article_id': this.articleId,
      'answer_type': 2,
      'answer_id': answerId,
      'user_id': this.userinfo.id,
      'parent_answer_id': answerId,
      'reply_answer_type': replyAnswerType,
      'content': this.answerComment,
    }
    if (this.replyBtnText == "回复") {
      body.answer_id = this.replyUserId;
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        // console.log("2级回复成功");
        this.answerComment = '';
        this.getReAnswer(body.parent_answer_id, key);
        // this.modalService.success({
        //   nzTitle: "系统提示",
        //   nzContent: "回复成功!",
        //   nzOkText: "关闭",
        //   nzOnOk: () => {
        //     this.quillContent = '';
        //     this.getArticleDetail();
        //     this.getAnswerList();
        //   },
        // })
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
    this.plackText = "评论ta的留言";
    this.replyBtnText = "提交评论";
    this.answerComment = '';
  }

  /**删除评论column/article_answer/deleteAnswer */
  delAnswer(reAnswer) {
    this.modalService.warning({
      nzTitle: '删除',
      nzContent: '<font>您确定要删除吗？</font>',
      nzOkText: '确定',
      nzOnOk: () => {
        let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|deleteanswer');
        var api = 'column/article_answer/deleteAnswer';
        var body = {
          'timestamp': new Date().getTime().toString().substr(0, 10),
          'signature': signature,
          'answer_id': reAnswer.id,
          'user_id': this.userinfo.id,
        }
        // console.log("body", body);
        this.http.doPost(api, body, (result) => {
          // console.log(result);
          if (result.code == "200") {
            this.getAnswerList();
          }
        })
      },
      nzCancelText: '取消',
      nzOnCancel: () => { },
    });
  }

  /**
   * 赞同操作question/answer_agree/addAgree
   * @param answerId 问题id
   * @param type 操作类型：1=赞同回答；2=取消赞同回答；3=赞同评论；4=取消赞同评论
   */
  addAgree(answerId, status) {
    var type = 1;
    if (status) {
      type = 4;
    } else if (!status) {
      type = 3;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addagree');
    var api = 'question/answer_agree/addAgree';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'type': type,
      'user_id': this.userinfo.id,
      'answer_id': answerId,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.getAnswerList();
      }
    })
  }

  /**
   * 收藏操作
   * @param status 操作类型：1=关注；2=收藏；3=取消关注；4=取消收藏；5=点赞；6=取消点赞
   */
  doCollect(status) {
    if (!this.checkUser()) {
      return;
    }
    var type = 2;
    if (status) {
      type = 4;
    } else if (!status) {
      type = 2;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|operate');
    var api = 'question/question_operate/operate';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userinfo.id,
      'question_id': this.articleId,
      'operate_type': type,
      'type': 1,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.getArticleDetail();
      } else {
        // this.modalService.error({
        //   nzTitle: "系统提示",
        //   nzContent: result.message,
        //   nzOkText: "关闭",
        //   nzOnOk: () => { },
        // })
      }
    })
  }

  /**
   * 赞同操作
   * @param articleId 文章id
   * @param status 
   */
  doAgree(articleId, status) {
    if (!this.checkUser()) {
      return;
    }
    // console.log('status:', status)
    var type = 1;
    if (status == 1) {
      type = 6;
    } else if (status == 0) {
      type = 5;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|operate');
    var api = 'question/question_operate/operate';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userinfo.id,
      'question_id': this.articleId,
      'operate_type': type,
      'type': 1,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.getArticleDetail();
        this.getAnswerList();
      } else {
        // this.modalService.error({
        //   nzTitle: "系统提示",
        //   nzContent: result.message,
        //   nzOkText: "关闭",
        //   nzOnOk: () => { },
        // })
      }
    })
  }

  /**
   * 回复答案
   * @param reAnswer 要回复的答案json
   */
  handReply(reAnswer, id) {
    this.plackText = "回复ta";
    this.replyBtnText = "回复";
    this.replyUserId = reAnswer.id;
    document.getElementById(id).focus();
  }

  EditorCreated(quill) {
    const toolbar = quill.getModule('toolbar');
    toolbar.addHandler('image', this.imageHandler.bind(this));
    this.editor = quill;
  }
  imageHandler() {
    const Imageinput = document.createElement('input');
    Imageinput.setAttribute('type', 'file');
    Imageinput.setAttribute('accept', 'image/png, image/gif, image/jpeg, image/bmp');
    Imageinput.classList.add('ql-image');
    Imageinput.addEventListener('change', () => {
      if (Imageinput.files != null && Imageinput.files[0] != null) {
        const file = Imageinput.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          var dataURL = reader.result;
          let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addcolumnlogo');
          var api = 'column/columns/addColumnLogo';
          var body = {
            'timestamp': new Date().getTime().toString().substr(0, 10),
            'signature': signature,
            'columnlogo': dataURL,
            'type': 2
          }
          this.http.doPost(api, body, (result) => {
            if (result.code == "200") {
              let imgUrl = 'https://admin.pgfans.cn' + result.data.url;
              this.editor.insertEmbed(this.editor.getSelection(true).index, 'image', imgUrl);
            }
          })
        };
      }
    });
    Imageinput.click();
  }

  btnTitle = '提交评论';
  doSubmit() {
    if (this.btnTitle == '提交评论') {
      this.doAddAnswer();
    } else {
      this.doEditQuestion();
    }
  }
  /**编辑答案column/article_answer/editAnswer */
  doEditQuestion() {
    // console.log("quillContent：", this.quillContent);    
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|editanswer');
    var api = 'column/article_answer/editAnswer';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'answer_id': this.answerId,
      'user_id': this.userinfo.id,
      'content': this.quillContent,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.btnTitle = '提交评论';
        this.quillContent = '';
        this.getAnswerList();
      }
    })
  }
  /**编辑回答 */
  editAnswer(answer) {
    this.answerId = answer.id;
    document.getElementById('writeAnswer').scrollIntoView(true);
    this.btnTitle = '修改答案';
    this.quillContent = answer.content;
  }

  /**
   * 检查是否已经登录
   */
  checkUser() {
    if (!this.userinfo) {
      let navigationExtras: NavigationExtras = {
        queryParams: { 'from': 'adetail', },
      }
      this.router.navigate(['/login'], navigationExtras)
      return false;
    }
    return true;
  }


  setShare(val) {
    // let linshi = 'http://service.weibo.com/share/share.php?title=Mob&url=http%3A%2F%2Fwww.baidu.com%2Fcode%2Fdemo%2F%23&pic=#_loginLayer_1574669916123'
    var linshi;
    console.log("detail:", this.articleDetail);
    if (val == 'weibo') {
      linshi = 'http://service.weibo.com/share/share.php?title=推荐【'
        + this.articleDetail.title
        + '】。传送门&url=https%3A%2F%2Fpgfans.cn%2Fa%2F'
        + this.articleDetail.id
    } else if (val == 'qq') {
      //https://connect.qq.com/widget/shareqq/index.html?url=https%3A%2F%2Fwww.uisdc.com%2Ftencent-animation-8-0&title=%E5%A5%BD%E6%96%87%EF%BC%81%E3%80%90%E7%82%B9%E5%87%BB%E7%8E%87%E6%8F%90%E5%8D%87115%25%EF%BC%81%E8%85%BE%E8%AE%AF%E5%8A%A8%E6%BC%AB8.0%E6%94%B9%E7%89%88%E5%AE%9E%E6%88%98%E5%AE%8C%E6%95%B4%E5%A4%8D%E7%9B%98%E3%80%91%E6%8E%A8%E8%8D%90%E7%BB%99%E5%A4%A7%E5%AE%B6%EF%BC%81%E5%8E%9F%E6%96%87%E6%88%B3%E2%86%92&summary=&site=baidu&pics=https%3A%2F%2Fimage.uisdc.com%2Fwp-content%2Fuploads%2F2020%2F01%2Fuisdc-banner-20200106-2.jpg
      linshi = 'https://connect.qq.com/widget/shareqq/index.html?title=推荐【'
        + this.articleDetail.title
        + '】。传送门&url=https%3A%2F%2Fpgfans.cn%2Fa%2F'
        + this.articleDetail.id
    }

    // yijianshengcai%3Futm_source%3DWeibo%26utm_medium%3DshareLink%26utm_campaign%3DsocialShare&pic=https%3A%2F%2Fcdn.segmentfault.com%2Fv-5dcd2518%2Fglobal%2Fimg%2Fuser-256.png&appkey=1742025894&searchPic=false#_loginLayer_1576841667829'

    window.open(linshi, '_blank')
  }

  isShow2code = false;
  qrcode = ''
  show2code() {
    this.qrcode = 'http://m.pgfans.cn/a/' + this.articleDetail.id;
    this.isShow2code = true;
  }
  codeCancel() {
    this.isShow2code = false;
  }

  //获取作者信息
  getAuthorInfo(id){   
     let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getnewinfo');
     var api = 'user/user/getNewInfo';
     var body = {
       'timestamp': new Date().getTime().toString().substr(0, 10),
       'signature': signature,
       'user_id': id,
       'my_user_id': 0,
       'sessionid': 0,
     }
     if (this.local.get('userinfo') == null) {
       body.my_user_id = 0;
     } else {
       body.my_user_id = this.local.get('userinfo').id;
       body.sessionid = this.local.get('userinfo').sessionid;
     }
     this.http.doPost(api, body, (result) => {
      // console.log(result);
       if (result.code == "200") {
         this.authorInfo = result.data;
       }
     })
  }

  //关注作者
  doFocusAuthor(type){
    if(this.local.get('userinfo') == null){
      this.router.navigate(['/login']);
      return;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|operate');
    var api = 'user/user_follow/operate';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.local.get('userinfo').id,
      'about_user_id': this.authorInfo.id,
      'type': type
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.getAuthorInfo(this.articleDetail.user_id);
      }
    })
  }
}

import { NzModalService } from 'ng-zorro-antd';
import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { Md5 } from "ts-md5";
import { ActivatedRoute, Router, RoutesRecognized, NavigationExtras } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { Meta, Title } from '@angular/platform-browser';
import { TimeHolder } from 'ng-zorro-antd/time-picker/time-holder';
declare var editormd: any;

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.less']
})
export class DetailComponent implements OnInit {

  userinfo: any = {};//本地保存的登录用户信息


  questionId = 0;                 //本页所属的问题id
  qd: any = {};                   //获取到的问题详情数据
  answerId = 0;                   //被回复答案的id，当answertype为1回答问题的时候，此处送0
  answer1List: any = [];          //该问题的一级回复
  answer2List: any = [];          //该问题的二级回复
  answerComment = '';             //双向绑定用于提交评论的输入框文本
  plackText = "评论ta的答案（请不要在评论里回答问题）";   //提交评论输入框的缺省文字
  replyBtnText = "提交评论";      //提交评论按钮的文字
  replyUserId = 0;

  labelList: any = [];            //所有标签列表
  similarQuestionList: any = [];  //相似问题列表

  advert = true;
  offsetTop = 0;
  adopt = false;

  value: any;   //临时，防止报错
  markdown: any;

  isMore = false;       //是否显示更多
  editor: any;
  quillContent: any;    //富文本数据
  quillConfig = {
    toolbar: [
      ['bold', 'italic',],
      ['link', 'blockquote', 'code-block', 'image'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, 'code-block', { 'header': 1 }, { 'header': 2 }],
      [{ 'indent': '-1' }, { 'indent': '+1' }], [{ 'color': [] }],
    ]
  };
  constructor(
    private local: StorageService,
    private modalService: NzModalService,
    private router: Router,
    public http: HttpService,
    private route: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
  ) {
    // route.queryParams.subscribe((data) => {
    //   this.questionId = data.id;
    // });
    this.route.params.subscribe(data=>{
      this.questionId=data.id;
    });
    this.userinfo = this.local.get('userinfo');
    this.advert = true;
    
      
    router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        // console.log("RoutesRecognized:", event.url);
        if (event.url.indexOf('q/') > -1) {
          // this.questionId = Number(event.url.substr(event.url.indexOf('q?id=') + 5));
          this.questionId = Number(event.url.split('/')[2]);
          this.getQuestion();
          this.getAnswerList(1);
          this.getSimilarQuestionList();
        }
      }
    });
  }

  ngOnInit() {
    // this.route.queryParams.subscribe((data) => {
    //   if(data.sms){
    //     if(sessionStorage.getItem('isFirst')==null){
    //       sessionStorage.setItem('isFirst','true');
    //       var div = "<script type='text/javascript'> var ip = returnCitySN['cip'];$('#info').text(ip)</script>"
    //       $('#body').append(div);
    //       var ip = $('#info').text();
    //       // this.addSms(ip);
    //     }
        
    //   }
    // });
     //获取所有问题标签列表:question/question_label/allLabelList
     this.getAllLabelList();

     //获取问答详情question/question/getQuestion
     this.getQuestion();
     //获取该问题的回答列表
     this.getAnswerList(1);
 
     //获取相似问题列表question/question/similarQuestionList
     this.getSimilarQuestionList();

  }

  ngOnDestroy(): void {
    this.titleService.setTitle('PGFans问答社区:全球唯一的PostgreSQL中文技术交流社区');
    this.meta.updateTag({ name: 'description', content: 'PGFans问答社区由中国开源软件联盟PostgreSQL分会发起，是全球唯一的PostgreSQL中文技术交流社区，PGFans问答社区主要服务于与PostgreSQL相关的DBA、应用开发、内核开发人员，作为世界上最先进的开源数据库，PostgreSQL发展趋势不断向上，并荣获2017、2018、2020年年度数据库。'});
    this.meta.updateTag({ name: 'keywords', content: 'PG社区,问答社区,技术社区'});
  }


  close(){
    this.advert = false;
  }
  /**
   * 获取所有问题标签列表question/question_label/allLabelList
   */
  getAllLabelList() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|questiontwolabellist');
    var api = 'question/question_label/questionTwoLabelList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'parent_id': 0,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.labelList = result.data;
        }
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }

  /**
   * 获取问答详情数据question/question/getQuestion
   */
  getQuestion() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getquestion');
    var api = 'question/question/getQuestion';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'question_id': this.questionId,
      'user_id': null,
    }
    if (this.userinfo) {
      body.user_id = this.userinfo.id;
    }
    this.http.doPost(api, body, (result) => {
    //  console.log(result)
      if (result.code == "200") {
        this.titleService.setTitle(result.data.title+'-'+result.data.user_name+'-PGFans问答社区：全球唯一的PostgreSQL中文技术交流社区');
        result.data.create_time = result.data.create_time + '000';
        this.qd = result.data;
        // console.log(this.qd)
        if (this.qd.content_type == 2) {
          this.qd.content = this.qd.content.replace('<p>', '');
          this.qd.content = this.qd.content.replace('</p>', '');
          if($('#q_html #content').html()==undefined){
            $('#q_html').empty();
            $('#q_html').append('<textarea id="content" style="display:none;" placeholder="markdown语言"></textarea>');
            $('#q_html #content').html(this.qd.content);
          }
          editormd.markdownToHTML('q_html');
        } else {
          this.qd.content = this.qd.content.replace(/\<img/g, "<img class='c_img' ")
          this.qd.content = this.qd.content + "<style>.c_img{max-width:100%;}p{margin:0 auto;}</style>"
          document.getElementById('q_html').innerHTML = this.qd.content;
        }
        this.setMeta();
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => {
            this.router.navigate(['/']);
          },
        })
      }
    })
  }

  //设置meta关键字
  setMeta(){
    var words = '';
    for(let i=0;i<this.qd.labelList.length;i++){
      words = words+this.qd.labelList[i].label+',';
    }
    // console.log(words)
    this.meta.updateTag({ name: 'description', content: this.qd.introduce});
    this.meta.updateTag({ name: 'keywords', content: words })
  }

  
  goEdit(id) {
    let navigationExtras: NavigationExtras = {
      queryParams: { q_id: id},
    }
    this.router.navigate(['/q-edit'], navigationExtras);
  }

  /**
   * 获取所有该问题的回答
   */
  getAnswerList(type) {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|answerlist');
    var api = 'question/answer/answerList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'answer_type': type,
      'parent_answer_id': 0,
      'question_id': this.questionId,
      'user_id': null,
    }
    if (this.userinfo) {
      body.user_id = this.userinfo.id;
    }
    this.http.doPost(api, body, (result) => {
      // console.log(result)
      if (result.code == "200") {
        this.answer1List = result.data;
        if (this.answer1List != null && this.answer1List.length > 0) {
          setTimeout(() => {
            for (let i = 0; i < this.answer1List.length; i++) {
              if (this.answer1List[i].content) {
                this.answer1List[i].content = this.answer1List[i].content + "<style>img{max-width:100%;}</style>";
              }
              // document.getElementById('answer_html' + i).innerHTML = this.answer1List[i].content;
            }
          }, 1);
          for(let i=0;i<this.answer1List.length;i++){
            this.answer1List[i].checked = true;
            this.answer1List[i].btn_checked = true;
            this.getReAnswer(this.answer1List[i].id, i,1)
            if(this.answer1List[i].adopt_status==1){
              this.adopt = true;
            }
          }
        }
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }

    })
  }
/**
 * 采纳答案question/answer/answerAdopt
 * @param answer_id 回答id
 */
  adoptAnswer(answer_id){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|answeradopt');
    var api = 'question/answer/answerAdopt';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'question_id': this.questionId,
      'user_id':this.userinfo.id,
      'answer_id':answer_id,
    }
    this.http.doPost(api, body, (result) => {
      // console.log(result)
      if (result.code == "200") {
        this.getAnswerList(1);  //刷新回答列表
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }

  doShowReAnswer(id, key) {
    // this.answer2List = [];
    if (this.answer1List[key].checked) {
      this.answer1List[key].checked = false;
      this.answer1List[key].btn_checked = false;
      return;
    }
    // for (let i = 0; i < this.answer1List.length; i++) {
    //   if (this.answer1List[i].checked) {
    //     this.answer1List[i].checked = false;
    //     this.answer1List[i].btn_checked = false;
    //   }
    // }
    this.getReAnswer(id, key,2);
  }
  /**
   * 获取此一级答案的二级评论列表
   */
  getReAnswer(id, key,type) {
    this.plackText = "评论ta的答案（请不要在评论里回答问题）";
    this.replyBtnText = "提交评论";
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|answerlist');
    var api = 'question/answer/answerList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'answer_type': 2,
      'parent_answer_id': id,
      'question_id': this.questionId,
      'user_id': null,
    }
    if (this.userinfo) {
      body.user_id = this.userinfo.id;
    }
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        this.answer2List[key] = result.data;
        if(type==1 && this.answer2List[key].length==0){
          this.answer1List[key].checked = false;
          this.answer1List[key].btn_checked = false;
        }else{
          for (let i = 0; i < this.answer1List.length; i++) {
            if (key == i) {
              this.answer1List[key].checked = true;
              this.answer1List[key].btn_checked = true;
              this.answer1List[key].answer_count = this.answer2List[key].length;
            }
          }
        }  
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }

  /**
   * 提交评论（二级回复）
   */
  addComment(answerId, key) {
    var replyAnswerType = 1;
    if (this.replyBtnText == "回复") {
      replyAnswerType = 2
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addanswer');
    var api = 'question/answer/addAnswer';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'question_id': this.questionId,
      'answer_type': 2,
      'answer_id': answerId,
      'user_id': this.userinfo.id,
      'parent_answer_id': answerId,
      'reply_answer_type': replyAnswerType,
      'content': this.answerComment,
    }
    if (this.replyBtnText == "回复") {
      body.answer_id = this.replyUserId;
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.getReAnswer(body.parent_answer_id, key,2);
        // this.modalService.success({
        //   nzTitle: "系统提示",
        //   nzContent: "回复成功!",
        //   nzOkText: "关闭",
        //   nzOnOk: () => {
        //     this.quillContent = '';
        //     // this.getQuestion();
        //     this.getAnswerList(1);
        //   },
        // })
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
    this.plackText = "评论ta";
    this.replyBtnText = "提交评论";
    this.answerComment = '';
  }

  /**
   * 获取相似问题列表question/question/similarQuestionList
   */
  getSimilarQuestionList() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|similarquestionlist');
    var api = 'question/question/similarQuestionList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'question_id': this.questionId,
    };
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        if (result.data != null && result.data.length > 0) {
          this.similarQuestionList = result.data;
        }
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }

  showMore() {
    this.isMore = !this.isMore;
  }
  showAll() {

  }

  /**
     * 上传文件
     * @param content
     */
  uploadFile(content) {
    // //附加额外参数
    // this.uploader.setOptions({
    //   additionalParameter: {
    //     'name': 'file',
    //     'version': '0.1',
    //     'repository': 'first'
    //   }
    // });
    // // 开始上传
    // this.uploader.queue[0].upload();
    // //判断是否上传成功
    // this.uploader.queue[0].onSuccess = function (response, status, headers) {
    //   // 上传文件成功
    //   if (status === 200) {
    //   // console.log('上传成功');
    //   } else {
    //   // console.log('上传失败');
    //   }
    // }
  }

  btnTitle = '提交答案';
  doSubmit() {
    if (this.btnTitle == '提交答案') {
      this.doAnswerQuestion();
    } else {
      this.doEditQuestion();
    }
  }
  /**提交答案 */
  doAnswerQuestion() {
    // console.log("quillContent：", this.quillContent);
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addanswer');
    var api = 'question/answer/addAnswer';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'question_id': this.questionId,
      'answer_type': 1,
      'answer_id': this.answerId,
      'user_id': this.userinfo.id,
      'parent_answer_id': 0,
      'reply_answer_type': 1,
      'content': this.quillContent,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.quillContent = '';
        // this.getQuestion();
        this.getAnswerList(1);
      } else {
        this.modalService.error({
          nzTitle: "系统提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }

  /**编辑答案question/answer/editAnswer */
  doEditQuestion() {
    // console.log("quillContent：", this.quillContent);
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|editanswer');
    var api = 'question/answer/editAnswer';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'answer_id': this.answerId,
      'user_id': this.userinfo.id,
      'content': this.quillContent,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.btnTitle = '提交答案';
        this.quillContent = '';
        this.getAnswerList(1);
      }
    })
  }

  /**创建新问题 */
  doAddQuestion() {
    this.router.navigate(['/ask']);
  }

  /**点击回答问题按钮触发事件 */
  handAnswer() {
    document.getElementById('writeAnswer').scrollIntoView(true);
    // return false;
  }

  /**编辑回答 */
  editAnswer(answer) {
    this.answerId = answer.id;
    document.getElementById('writeAnswer').scrollIntoView(true);
    this.btnTitle = '修改答案';
    this.quillContent = answer.content;
  }

  /**删除问题question/question/deleteQuestion */
  delQuestion(question) {
    this.modalService.warning({
      nzTitle: '删除',
      nzContent: '<font>您确定要删除吗？</font>',
      nzOkText: '确定',
      nzOnOk: () => {
        let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|deletequestion');
        var api = 'question/question/deleteQuestion';
        var body = {
          'timestamp': new Date().getTime().toString().substr(0, 10),
          'signature': signature,
          'question_id': question.id,
          'user_id': this.userinfo.id,
        }
        // console.log("body", body);
        this.http.doPost(api, body, (result) => {
          // console.log(result);
          if (result.code == "200") {
            this.modalService.success({
              nzTitle: "系统提示",
              nzContent: "删除问题成功",
              nzOkText: "关闭",
              nzOnOk: () => {
                window.history.back();
              },
            })
          }
        })
      },
      nzCancelText: '取消',
      nzOnCancel: () => { },
    });
  }

  /**删除答案question/answer/deleteAnswer */
  delAnswer(answer) {
    this.modalService.warning({
      nzTitle: '删除',
      nzContent: '<font>该操作不可逆，是否确认删除？</font>',
      nzOkText: '确认',
      nzOnOk: () => {
        let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|deleteanswer');
        var api = 'question/answer/deleteAnswer';
        var body = {
          'timestamp': new Date().getTime().toString().substr(0, 10),
          'signature': signature,
          'answer_id': answer.id,
          'user_id': this.userinfo.id,
        }
        // console.log("body", body);
        this.http.doPost(api, body, (result) => {
          // console.log(result);
          if (result.code == "200") {
            this.getAnswerList(1);
          }
        })
      },
      nzCancelText: '取消',
      nzOnCancel: () => { },
    });
  }

  /**删除评论column/article_answer/deleteAnswer */
  delReAnswer(reAnswer) {
    this.modalService.warning({
      nzTitle: '删除',
      nzContent: '<font>您确定要删除吗？</font>',
      nzOkText: '确定',
      nzOnOk: () => {
        let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|deleteanswer');
        var api = 'column/article_answer/deleteAnswer';
        var body = {
          'timestamp': new Date().getTime().toString().substr(0, 10),
          'signature': signature,
          'answer_id': reAnswer.id,
          'user_id': this.userinfo.id,
        }
        // console.log("body", body);
        this.http.doPost(api, body, (result) => {
          // console.log(result);
          if (result.code == "200") {
          }
        })
      },
      nzCancelText: '取消',
      nzOnCancel: () => { },
    });
  }

  /**
   * 关注操作
   * @param status 操作类型：1=关注；2=收藏；3=取消关注；4=取消收藏；5=点赞；6=取消点赞
   */
  doFollow(status) {
    if (!this.checkUser()) {
      return;
    }
    var type = 1;
    if (status) {
      type = 3;
    } else if (!status) {
      type = 1;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|operate');
    var api = 'question/question_operate/operate';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userinfo.id,
      'question_id': this.questionId,
      'operate_type': type,
      'type': 2,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.getQuestion();
      } else {
        // this.modalService.error({
        //   nzTitle: "系统提示",
        //   nzContent: result.message,
        //   nzOkText: "关闭",
        //   nzOnOk: () => { },
        // })
      }
    })
  }
  contentChanged(event) {
    this.quillContent = event.html;  // 内容编辑
  }

  /**
   * 收藏操作
   * @param status 操作类型：1=关注；2=收藏；3=取消关注；4=取消收藏；5=点赞；6=取消点赞
   */
  doCollect(status) {
    if (!this.checkUser()) {
      return;
    }
    var type = 2;
    if (status) {
      type = 4;
    } else if (!status) {
      type = 2;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|operate');
    var api = 'question/question_operate/operate';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id': this.userinfo.id,
      'question_id': this.questionId,
      'operate_type': type,
      'type': 2,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.getQuestion();
      } else {
        // this.modalService.error({
        //   nzTitle: "系统提示",
        //   nzContent: result.message,
        //   nzOkText: "关闭",
        //   nzOnOk: () => { },
        // })
      }
    })
  }

  /**
   * 赞同操作
   * @param answerId 问题id
   * @param type 操作类型：1=赞同回答；2=取消赞同回答；3=赞同评论；4=取消赞同评论
   */
  doAgree(answerId, status) {
    if (!this.checkUser()) {
      return;
    }
    var type = 1;
    if (status) {
      type = 2;
    } else if (!status) {
      type = 1;
    }
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addagree');
    var api = 'question/answer_agree/addAgree';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'type': type,
      'answer_id': answerId,
      'user_id': this.userinfo.id,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.getAnswerList(1);
      } else {
        // this.modalService.error({
        //   nzTitle: "系统提示",
        //   nzContent: result.message,
        //   nzOkText: "关闭",
        //   nzOnOk: () => { },
        // })
      }
    })
  }

  /**
   * 回复答案
   * @param reAnswer 要回复的答案json
   */
  handReply(reAnswer, id) {
    this.plackText = "回复ta";
    this.replyBtnText = "回复";
    this.replyUserId = reAnswer.id;
    document.getElementById(id).focus();
  }
  EditorCreated(quill) {
    const toolbar = quill.getModule('toolbar');
    toolbar.addHandler('image', this.imageHandler.bind(this));
    this.editor = quill;
  }
  imageHandler() {
    const Imageinput = document.createElement('input');
    Imageinput.setAttribute('type', 'file');
    Imageinput.setAttribute('accept', 'image/png, image/gif, image/jpeg, image/bmp');
    Imageinput.classList.add('ql-image');
    Imageinput.addEventListener('change', () => {
      if (Imageinput.files != null && Imageinput.files[0] != null) {
        const file = Imageinput.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          var dataURL = reader.result;
          let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|addcolumnlogo');
          var api = 'column/columns/addColumnLogo';
          var body = {
            'timestamp': new Date().getTime().toString().substr(0, 10),
            'signature': signature,
            'columnlogo': dataURL,
            'type': 2
          }
          this.http.doPost(api, body, (result) => {
            if (result.code == "200") {
              let imgUrl = 'https://admin.pgfans.cn' + result.data.url;
              this.editor.insertEmbed(this.editor.getSelection(true).index, 'image', imgUrl);
            }
          })
        };
      }
    });
    Imageinput.click();
  }

  /**
   * 跳转到标签详情页面
   * @param label 标签数据
   */
  goLabel(label) {
    // let navigationExtras: NavigationExtras = {
    //   queryParams: { 'id': label.id, },
    // }
    this.router.navigate(['/labels-detail/'+label.id]);
  }

  /**
   * 检查是否已经登录
   */
  checkUser() {
    // console.log("检查");
    if (!this.userinfo) {
      // console.log("未登录");
      let navigationExtras: NavigationExtras = {
        queryParams: { 'from': 'qdetail', },
      }
      this.router.navigate(['/login'], navigationExtras)
      return false;
    }
    return true;
  }

  setShare(val) {
    // let linshi = 'http://service.weibo.com/share/share.php?title=Mob&url=http%3A%2F%2Fwww.baidu.com%2Fcode%2Fdemo%2F%23&pic=#_loginLayer_1574669916123'
    var linshi;
    if (val == 'weibo') {
      linshi = 'http://service.weibo.com/share/share.php?title=推荐【'
        + this.qd.title
        + '】。传送门&url=https%3A%2F%2Fpgfans.cn%2Fq%2F'
        + this.qd.id
    } else if (val == 'qq') {
      //https://connect.qq.com/widget/shareqq/index.html?url=https%3A%2F%2Fwww.uisdc.com%2Ftencent-animation-8-0&title=%E5%A5%BD%E6%96%87%EF%BC%81%E3%80%90%E7%82%B9%E5%87%BB%E7%8E%87%E6%8F%90%E5%8D%87115%25%EF%BC%81%E8%85%BE%E8%AE%AF%E5%8A%A8%E6%BC%AB8.0%E6%94%B9%E7%89%88%E5%AE%9E%E6%88%98%E5%AE%8C%E6%95%B4%E5%A4%8D%E7%9B%98%E3%80%91%E6%8E%A8%E8%8D%90%E7%BB%99%E5%A4%A7%E5%AE%B6%EF%BC%81%E5%8E%9F%E6%96%87%E6%88%B3%E2%86%92&summary=&site=baidu&pics=https%3A%2F%2Fimage.uisdc.com%2Fwp-content%2Fuploads%2F2020%2F01%2Fuisdc-banner-20200106-2.jpg
      linshi = 'https://connect.qq.com/widget/shareqq/index.html?title=推荐【'
        + this.qd.title
        + '】。传送门&url=https%3A%2F%2Fpgfans.cn%2Fq%2F'
        + this.qd.id
    }

    // yijianshengcai%3Futm_source%3DWeibo%26utm_medium%3DshareLink%26utm_campaign%3DsocialShare&pic=https%3A%2F%2Fcdn.segmentfault.com%2Fv-5dcd2518%2Fglobal%2Fimg%2Fuser-256.png&appkey=1742025894&searchPic=false#_loginLayer_1576841667829'

    window.open(linshi, '_blank');

  }

  isShow2code = false;
  qrcode = ''
  show2code() {
    this.qrcode = 'http://m.pgfans.cn/q/' + this.qd.id;
    this.isShow2code = true;
  }
  codeCancel() {
    this.isShow2code = false;
  }
}
